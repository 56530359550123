import React from 'react';
import styled from 'styled-components';

const PikaIconSpanContainer = styled.span`
  &::before {
    vertical-align: middle;
  }
`;

export interface PikaIconProps {
  className?: string;
  icon: string;
}

export const PikaIcon: React.FC<PikaIconProps> = ({ className, icon }) => {
  return <PikaIconSpanContainer className={`${className ?? ''} ${icon}`} />;
};
