import React, { useState } from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import styled from 'styled-components';
import {
  useUserPreferences,
  useUserPreferencesDispatch,
  UserPreferencesType,
} from '../../context/UserPreferences';
import Language, { LanguageKey } from '../../types/Language';

const LanguageSelectorButton = styled.button`
  background: #fff;
  border: 0;
  color: #707070;
  cursor: pointer;
  outline: none;
  padding: 17px 28px;
  border-left: 1px solid #eee;

  &:hover {
    background: #f7f7f7;
    outline: none;
  }
`;

const LanguageSelectorDropdown = styled.div`
  position: absolute;
  top: 62px;
  background: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.29);
  transition: visibility 0.5 ease-out, opacity 0.5 ease-out;
  visibility: hidden;
  opacity: 0;
  z-index: 1;

  &.open {
    transition: visibility 0.5 ease-out, opacity 0.5 ease-out;
    visibility: visible;
    opacity: 1;
  }

  &&& .MuiListItem-root {
    padding-left: 20px;
    padding-right: 20px;

    & > span:first-child {
      margin-right: 10px;
    }
  }
`;

const LanguageSelector = (): JSX.Element => {
  const userPreferences = useUserPreferences();
  const userPreferencesDispatch = useUserPreferencesDispatch();
  const [showLanguageDropdown, setShowLanguageDropdown] = useState<boolean>(
    false
  );

  const handleSelectLanguage = (language: Language) => () => {
    if (language !== userPreferences.language) {
      userPreferencesDispatch({
        type: UserPreferencesType.CHANGE_LANGUAGE,
        payload: {
          language: language,
        },
      });
    }
  };
  return (
    <>
      <LanguageSelectorButton
        onMouseEnter={() => setShowLanguageDropdown(true)}
        onMouseLeave={() => setShowLanguageDropdown(false)}
      >
        <>
          {userPreferences.language}
          <LanguageSelectorDropdown
            className={showLanguageDropdown ? 'open' : ''}
            onMouseEnter={() => setShowLanguageDropdown(true)}
            onMouseLeave={() => setShowLanguageDropdown(false)}
          >
            <List>
              {Object.keys(Language).map((key) => {
                const language = Language[key as LanguageKey];
                return (
                  <ListItem
                    key={language}
                    button
                    onClick={handleSelectLanguage(
                      (language as unknown) as Language
                    )}
                  >
                    <ListItemText primary={language} />
                  </ListItem>
                );
              })}
            </List>
          </LanguageSelectorDropdown>
        </>
      </LanguageSelectorButton>
    </>
  );
};

export default LanguageSelector;
