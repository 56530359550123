import { Container, List, ListItem, ListItemText } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/logo.svg';
import LanguageSelector from '../components/LanguageSelector';
import { PikaIcon } from '../components/PikaIcon';
import { User } from '../types/User';
import PageLayout from './PageLayout';
import SettingsIcon from './SettingsIcon';

const MainLayoutContainer = styled.div`
  padding-bottom: 20px;
  min-height: 100%;
  background-color: #fff;
`;

const StyledHeader = styled.header`
  background: #fff;
  box-shadow: 1px 1px 2px #f5f5f5;
  margin-bottom: 60px;
  width: 100%;
`;

const LogoWrapper = styled.div`
  display: inline-block;
  position: absolute;
  top: 12px;
  left: 32px;

  @media (max-width: 1440px) {
    display: none;
  }
`;

const Logo = styled.img`
  width: 22px;
`;

const StyledNav = styled.nav`
  font-family: Lato;
  width: 100%;
`;

const NavMenu = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const NavMenuItem = styled.li`
  padding: 0 20px;

  &:first-child {
    padding-left: 0;
  }

  & > a {
    color: #333;
    display: block;
    font-size: 14px;
    font-weight: 700;
    line-height: 3.456;
    padding: 5px 0;
    text-decoration: none;

    &.active {
      color: #0076a5;
      border-bottom: 4px solid #0076a5;
    }
  }
`;

const UserActions = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
`;

const UserNameWrapper = styled.div`
  padding: 17px 22px;
  white-space: nowrap;
`;

const SettingsButton = styled.button`
  background: #fff;
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 17px 28px;
  border-left: 1px solid #eee;

  &:hover {
    background: #f7f7f7;
    outline: none;
  }
`;

const UserActionsDropdown = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.29);
  transition: visibility 0.5s ease-out, opacity 0.5s ease-out;
  visibility: hidden;
  opacity: 0;
  z-index: 1;

  &.open {
    transition: visibility 0.5s ease-out, opacity 0.5s ease-out;
    visibility: visible;
    opacity: 1;
  }

  &&& .MuiListItem-root {
    padding-left: 20px;
    padding-right: 20px;

    & > span:first-child {
      margin-right: 10px;
    }
  }
`;

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout = ({ children }: MainLayoutProps) => {
  const { t } = useTranslation('menu');
  const { keycloak } = useKeycloak();
  const [givenName, setGivenName] = useState<string>('');

  const [settingsDropdownIsShown, showSettingsDropdown] = useState<boolean>(
    false
  );

  useEffect(() => {
    keycloak
      ?.loadUserInfo()
      .then((user: User) => setGivenName(user?.given_name ?? ''));
  }, [keycloak]);

  const signOut = () => {
    keycloak && keycloak.logout();
  };

  return (
    <MainLayoutContainer>
      <StyledHeader>
        <LogoWrapper>
          <NavLink exact to="/">
            <Logo src={logo} />
          </NavLink>
        </LogoWrapper>
        <Container>
          <StyledNav>
            <NavMenu>
              <NavMenuItem>
                <NavLink exact to="/" activeClassName="active">
                  {t('menu:myTests')}
                </NavLink>
              </NavMenuItem>
              <NavMenuItem>
                <NavLink to="/certificates" activeClassName="active">
                  {t('menu:diplomas')}
                </NavLink>
              </NavMenuItem>
              <LanguageSelector />
            </NavMenu>
          </StyledNav>
        </Container>
        <UserActions>
          <UserNameWrapper>{givenName}</UserNameWrapper>
          <SettingsButton
            onMouseEnter={() => showSettingsDropdown(true)}
            onMouseLeave={() => showSettingsDropdown(false)}
          >
            <SettingsIcon />
          </SettingsButton>
        </UserActions>
        <UserActionsDropdown
          className={settingsDropdownIsShown ? 'open' : ''}
          onMouseEnter={() => showSettingsDropdown(true)}
          onMouseLeave={() => showSettingsDropdown(false)}
        >
          <List>
            <ListItem button onClick={signOut}>
              <PikaIcon icon="ss-logout" />
              <ListItemText primary={t('menu:signOut')} />
            </ListItem>
          </List>
        </UserActionsDropdown>
      </StyledHeader>
      <PageLayout>{children}</PageLayout>
    </MainLayoutContainer>
  );
};

export default MainLayout;
