import React from 'react';
import styled, { css } from 'styled-components';

export interface LoadingIconProps {
  size?: 'sm' | 'md' | 'lg';
}

// CSS copied from: https://loading.io/css/
const LDSRing = styled.div<LoadingIconProps>`
  display: inline-block;
  position: relative;

  ${({ size }) => {
    let width;
    let height;

    switch (size) {
      case 'sm':
        width = 40;
        height = 40;
        break;

      case 'lg':
        width = 60;
        height = 60;
        break;

      case 'md':
      default:
        width = 50;
        height = 50;
        break;
    }

    return css`
      width: ${width}px;
      height: ${height}px;
    `;
  }}

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    margin: 8px;

    ${({ size }) => {
      let width;
      let height;
      let borderWidth;

      switch (size) {
        case 'sm':
          width = 22;
          height = 22;
          borderWidth = 3;
          break;

        case 'lg':
          width = 42;
          height = 42;
          borderWidth = 5;
          break;

        case 'md':
        default:
          width = 32;
          height = 32;
          borderWidth = 4;
          break;
      }

      return css`
        width: ${width}px;
        height: ${height}px;
        border: ${borderWidth}px solid #0076a5;
      `;
    }}

    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0076a5 transparent transparent transparent;
  }

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }

  & div:nth-child(2) {
    animation-delay: -0.3s;
  }

  & div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoadingIcon: React.FC<LoadingIconProps> = ({
  size,
}): JSX.Element => {
  return (
    <LDSRing size={size}>
      <div />
      <div />
      <div />
      <div />
    </LDSRing>
  );
};

export interface LoaderProps extends Pick<LoadingIconProps, 'size'> {
  children?: React.ReactNode;
  align?: 'start' | 'center' | 'end';
}

const LoaderContainer = styled.div<LoaderProps>`
  display: flex;
  justify-content: ${({ align }) => align || 'start'};
  align-items: center;
`;

const StyledText = styled.p<LoaderProps>`
  color: #999;
  font-family: Lato;
  font-size: ${({ size }) => {
    if (size === 'sm') return 13;
    if (size === 'lg') return 15;
    return 14;
  }}px;
  line-height: 1;
`;

export const Loader: React.FC<LoaderProps> = ({
  children,
  align,
  size,
}): JSX.Element => {
  return (
    <LoaderContainer align={align}>
      <LoadingIcon size={size} />
      {children && <StyledText size={size}>{children}</StyledText>}
    </LoaderContainer>
  );
};
