import React from 'react';
import styled from 'styled-components';
import { Typography, Button } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

const ContainerTopSection = styled.div`
  margin: 50px 0 80px;
  &&& > h2 {
    font-family: Lato;
    font-weight: 600;
    margin-bottom: 16px;
  }
`;

const StyledAlert = styled(Alert)`
  && {
    margin-bottom: 1rem;
  }
`;

const StyledDescription = styled(Typography)`
  && {
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
    line-height: 1.2;
    color: #333333;
    font-weight: 300;
    margin-top: 0.9rem;
    max-width: 750px;
  }
`;

const StyledDiv = styled.div`
  && {
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
    line-height: 1.2;
    color: #333333;
    font-weight: 300;
    margin-top: 0.9rem;
    max-width: 750px;
    display: block;
  }
`;

const StyledHeader = styled.h1`
  font-size: 1.5rem;
  color: #2d2f39;
  margin-top: 0;
  margin-bottom: 0;
`;

const StyledQuestionHeader = styled(StyledHeader)`
  font-weight: bold;
`;

const StyledButton = styled(
  ({ children, back, ...props }): JSX.Element => (
    <Button {...props}>{...children}</Button>
  )
)`
  && {
    font-family: 'Lato', sans-serif;
    border: none;
    padding: 0.8rem 2.8rem;
    outline: none;
    cursor: pointer;
    width: auto;
    border-radius: 4px;
    color: #ffffff !important;
    background-color: ${(props) => (props.back ? '#0076a5' : '#0076a5')};
    text-decoration: none;
    font-weight: bold;
    margin-left: 10px;
    opacity: ${(props) => (props.disabled ? '15%' : '1')};
  }

  &:hover {
    color: black;
  }
`;

const StyledNavLink = styled(
  ({ children, back, ...props }): JSX.Element => (
    <NavLink {...props}>{...children}</NavLink>
  )
)`
  && {
    font-family: 'Lato', sans-serif;
    border: none;
    padding: 0.8rem 2.8rem;
    outline: none;
    cursor: pointer;
    width: auto;
    border-radius: 4px;
    color: #ffffff;
    background-color: ${(props) => (props.back ? '#0076a5' : '#0076a5')};
    text-decoration: none;
  }

  &:hover {
    color: black;
  }
`;

const StyledExaminationsWrapper = styled.section`
  padding: 2.5rem;
  background-color: #f9f9f9;
`;

const StyledResultsWrapper = styled(StyledExaminationsWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.6rem;
  h3 {
    font-size: 1.6rem;
    color: #2d2f39;
    max-width: 500px;
    font-weight: 900;
    margin-top: 0;
    line-height: 1.3;
  }
  a {
    color: #0076a5;
    text-decoration: underline;
    font-weight: 700;
    font-size: 1rem;
  }
`;

const RoundedWarningContainer = styled.div`
  width: 86px;
  height: 86px;
  background: #ffb81c 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font: var(--unnamed-font-style-normal) normal normal 42px /
      var(--unnamed-line-spacing-60) SS Pika;
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal normal 42px/60px SS Pika;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
`;

const RoundedApprovedContainer = styled.div`
  width: 86px;
  height: 86px;
  background: #36c485 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font: var(--unnamed-font-style-normal) normal normal 42px /
      var(--unnamed-line-spacing-60) SS Pika;
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal normal 42px/60px SS Pika;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
`;

export {
  ContainerTopSection,
  StyledDescription,
  StyledHeader,
  StyledQuestionHeader,
  StyledNavLink,
  StyledExaminationsWrapper,
  StyledResultsWrapper,
  StyledButton,
  StyledAlert,
  StyledDiv,
  RoundedWarningContainer,
  RoundedApprovedContainer,
};
